'use strict';

angular.module('cpformplastApp.deliveries')
    .controller('DeliveryHistoryController', function($scope, DataManager, $state, Notification, $modal) {

  $scope.deliveries = [];

  $scope.deliveryTableStructure = [{
    'title':'Client',
    'value':'client',
    'filter':'alpha'
  },{
    'title':'Transporteur',
    'value':'shipper',
    'filter':'alpha'
  },{
    'title':'#Booking',
    'value':'booking',
    'filter':'alpha'
  },{
    'title':'#BOL',
    'value':'bol',
    'filter':'alpha'
  },{
    'title':'Date',
    'value':'date',
    'filter':'date'
  },{
    'title':'Details',
    'value':'details',
    'filter':'alpha'
  }];

  $scope.init = function() {
    DataManager.fetchHistoryDeliveries().then((data) => {
      $scope.deliveries = data;
      for (var i=0 ; i<$scope.deliveries.length ; i++) {
        $scope.deliveries[i].date = new Date($scope.deliveries[i].date);
      }
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un problème est survenu lors du chargement des livraisons');
    });
  };

  $scope.selectDelivery = function(delivery){
    var modalInstance = $modal.open({
      animation: true,
      templateUrl: '../../components/modal/info/info-delivery-modal.html',
      controller: 'InfoDeliveryModalController',
      resolve:{
        delivery: function(){
          return delivery;
        }
      }
    });
  };

  $scope.init();

});
